.footer {
    padding: 20px;
    background-color: #04192e; /* Dark navy color */
    color: white;
    text-align: center;
    position: relative;
  }
  
  .footer-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 10px;
  }
  
  .footer .footer-logo {
    flex: 1;
    text-align: left;
  }
  
  .footer .footer-logo img {
    height: 60px;
    transition: transform 0.2s ease-in-out, filter 0.3s ease-in-out;
  }
  
  .footer .footer-logo img:hover {
    transform: scale(1.1);
    filter: drop-shadow(0 0 10px #21a1f1);
  }
  
  .footer .social-links {
    display: flex;
    gap: 20px;
    flex: 1;
    justify-content: flex-end;
  }
  
  .footer .social-links a {
    color: white;
    text-decoration: none;
    font-size: 40px; /* Increased size */
    transition: transform 0.2s ease-in-out, filter 0.3s ease-in-out;
  }
  
  .footer .social-links a:hover {
    color: #21a1f1; /* Light green color for the glow effect */
    filter: drop-shadow(0 0 10px #21a1f1);
  }
  
  .footer-rights {
    margin-top: 10px;
    font-size: 14px;
    width: 100%;
  }
  
  @media (max-width: 768px) {
    .footer-content {
      flex-direction: column;
      align-items: center;
    }
  
    .footer .footer-logo {
      text-align: center;
      margin-bottom: 10px;
    }
  
    .footer .social-links {
      justify-content: center;
    }
  }
  
  @media (max-width: 480px) {
    .footer .social-links a {
      font-size: 30px;
    }
  
    .footer-rights {
      font-size: 12px;
    }
  }
  