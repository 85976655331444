.contact {
    padding: 50px;
    text-align: center;
    background: url('../../public/background.jpg') no-repeat center center/cover;
    background-size: cover;
    position: relative;
    color: white;
    min-height: calc(100vh - 120px); /* Adjusting for header and footer height */
  }
  
  .contact::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(4, 25, 46, 0.85); /* Dark overlay */
    z-index: 1;
    backdrop-filter: blur(2px); /* Ensure the overlay blurs the background */
  }
  
  .contact .container {
    position: relative;
    z-index: 2;
    filter: blur(0);
    animation: fadeIn 1.5s ease-in-out;
    max-width: 800px;
    margin: 0 auto;
  }
  
  .contact h1 {
    font-size: 48px;
    margin-bottom: 20px;
  }
  
  .contact .card {
    background: #333; /* Dark transparent background */
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(43, 42, 42, 0.3);
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .contact-details {
    margin-bottom: 20px;
    text-align: left;
    width: 100%;
  }
  
  .contact-details h2 {
    margin-bottom: 15px;
    font-size: 28px;
    border-bottom: 2px solid #61dafb;
    padding-bottom: 10px;
  }
  
  .contact-details p {
    font-size: 18px;
    margin: 5px 0;
    line-height: 1.6;
  }
  
  .contact-form {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 90%;
  }
  
  .contact-form label {
    font-size: 18px;
    text-align: left;
    margin-bottom: 5px;
  }
  
  .contact-form input,
  .contact-form textarea {
    padding: 15px;
    border-radius: 5px;
    border: none;
    font-size: 16px;
    width: 100%;
    background: rgba(255, 255, 255, 0.9);
    color: #333;
  }
  
  .contact-form textarea {
    resize: none;
    height: 150px;
  }
  
  .submit-button {
    padding: 15px 20px;
    font-size: 18px;
    color: white;
    background-color: #61dafb;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out, transform 0.3s ease-in-out;
  }
  
  .submit-button:hover {
    background-color: #21a1f1;
    transform: scale(1.05);
  }
  
  @keyframes fadeIn {
    0% { opacity: 0; transform: translateY(-20px); }
    100% { opacity: 1; transform: translateY(0); }
  }
  
  @media (max-width: 768px) {
    .contact {
      padding: 30px;
    }
  
    .contact h1 {
      font-size: 36px;
    }
  
    .contact .container {
      padding: 20px;
    }
  
    .contact-details h2 {
      font-size: 24px;
    }
  
    .contact-details p {
      font-size: 16px;
    }
  
    .contact-form label {
      font-size: 16px;
    }
  
    .contact-form input,
    .contact-form textarea {
      font-size: 14px;
    }
  
    .submit-button {
      font-size: 16px;
      padding: 12px 16px;
    }
  }
  
  @media (max-width: 480px) {
    .contact {
      padding: 20px;
    }
  
    .contact h1 {
      font-size: 28px;
    }
  
    .contact .container {
      padding: 15px;
    }
  
    .contact-details h2 {
      font-size: 20px;
    }
  
    .contact-details p {
      font-size: 14px;
    }
  
    .contact-form label {
      font-size: 14px;
    }
  
    .contact-form input,
    .contact-form textarea {
      font-size: 12px;
    }
  
    .submit-button {
      font-size: 14px;
      padding: 10px 14px;
    }
  }
  