.header {
    display: flex;
    justify-content: center; /* Center the navigation links */
    align-items: center;
    padding: 20px;
    background-color: #04192e; /* Dark navy blue color */
    position: relative;
  }
  
  .header .logo img {
    height: 80px;
    position: absolute; /* Position logo absolute to keep nav links centered */
    left: 50px; /* Adjust position as needed */
    margin-top: -40px;
    transition: filter 0.2s ease-in-out;
  }
  
  .header .logo img:hover {
    filter: drop-shadow(0 0 10px rgba(0, 255, 0, 0.7)) drop-shadow(0 0 20px rgba(0, 255, 0, 0.5));
  }
  
  .header nav ul {
    list-style-type: none;
    display: flex;
    gap: 24px;
  }
  
  .header nav ul li a {
    color: white;
    text-decoration: none;
    font-size: 24px;
    transition: text-shadow 0.3s ease-in-out;
  }
  
  .header nav ul li a:hover {
    text-shadow: 0 0 10px rgba(0, 255, 0, 0.7); /* Green glow effect */
    text-decoration: none; /* Removing underline on hover */
  }
  
  .burger {
    display: none;
    flex-direction: column;
    justify-content: space-between;
    width: 30px;
    height: 21px;
    position: absolute;
    right: 20px;
    cursor: pointer;
  }
  
  .burger .line {
    width: 100%;
    height: 3px;
    background-color: white;
    transition: all 0.3s ease-in-out;
  }
  
  .line1-open {
    transform: rotate(45deg) translate(5px, 5px);
  }
  
  .line2-open {
    opacity: 0;
  }
  
  .line3-open {
    transform: rotate(-45deg) translate(6px, -6px);
  }
  
  .nav-open {
    display: block;
  }
  
  @media (max-width: 768px) {
    .header {
      flex-direction: column;
      padding: 10px;
    }
  
    .header .logo img {
      position: static; /* Make the logo flow with the rest of the content on smaller screens */
      margin-bottom: 10px;
      margin-top: 10px;
    }
  
    .header nav {
      display: none;
    }
  
    .header nav.nav-open {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }
  
    .burger {
      display: flex;
    }
  
    .header nav ul {
      flex-direction: column;
      gap: 10px;
    }
  }
  