.about {
    padding: 50px;
    text-align: center;
    background: url('../../public/background.jpg') no-repeat center center/cover;
    background-size: cover;
    position: relative;
    color: white;
    min-height: calc(100vh - 120px); /* Adjusting for header and footer height */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .about::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(4, 25, 46, 0.85); /* Dark overlay */
    z-index: 1;
    backdrop-filter: blur(2px); /* Ensure the overlay blurs the background */
  }
  
  .about .content {
    position: relative;
    z-index: 2;
    filter: blur(0);
    animation: fadeIn 1.5s ease-in-out;
  }
  
  .about h1 {
    font-size: 48px;
    margin-bottom: 20px;
  }
  
  .about .container {
    max-width: 800px;
    margin: 20px auto;
    text-align: left;
    padding: 20px;
    background: rgba(255, 255, 255, 0.1); /* Slightly transparent background for containers */
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s ease-in-out; /* Add transition for smooth hover effect */
  }
  
  .about .container:hover {
    transform: translateY(-10px); /* Move container up slightly on hover */
  }
  
  .about .container h2 {
    font-size: 36px;
    margin-bottom: 10px;
  }
  
  .about p {
    font-size: 24px;
    margin: 10px 0;
    line-height: 1.6;
  }
  
  .cta-button {
    padding: 10px 20px;
    font-size: 18px;
    color: #04192e;
    background-color: #61dafb;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out, transform 0.3s ease-in-out;
    margin-top: 20px;
  }
  
  .cta-button:hover {
    background-color: #21a1f1;
    transform: scale(1.1);
  }
  
  @keyframes fadeIn {
    0% { opacity: 0; transform: translateY(-20px); }
    100% { opacity: 1; transform: translateY(0); }
  }
  
  @media (max-width: 768px) {
    .about {
      padding: 30px;
    }
  
    .about h1 {
      font-size: 36px;
    }
  
    .about .container {
      padding: 15px;
    }
  
    .about .container h2 {
      font-size: 30px;
    }
  
    .about p {
      font-size: 18px;
    }
  
    .cta-button {
      font-size: 16px;
      padding: 8px 16px;
    }
  }
  
  @media (max-width: 480px) {
    .about {
      padding: 20px;
    }
  
    .about h1 {
      font-size: 28px;
    }
  
    .about .container {
      padding: 10px;
    }
  
    .about .container h2 {
      font-size: 24px;
    }
  
    .about p {
      font-size: 16px;
    }
  
    .cta-button {
      font-size: 14px;
      padding: 6px 12px;
    }
  }
  