.home {
    padding: 50px;
    text-align: center;
    background: url('../../public/background.jpg') no-repeat center center/cover;
    background-size: cover;
    position: relative;
    color: white;
    min-height: calc(100vh - 120px); /* Adjusting for header and footer height */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .home::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(4, 25, 46, 0.85); /* Dark overlay */
    z-index: 1;
    backdrop-filter: blur(2px); /* Ensure the overlay blurs the background */
  }
  
  .home .content {
    position: relative;
    z-index: 2;
    filter: blur(0);
    animation: fadeIn 1.5s ease-in-out;
  }
  
  .home h1 {
    font-size: 48px;
    margin-bottom: 20px;
  }
  
  .home p {
    font-size: 24px;
  }
  
  .cta-button {
    display: inline-block;
    padding: 10px 20px;
    font-size: 18px;
    color: #04192e;
    background-color: #61dafb;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out, transform 0.3s ease-in-out;
    text-decoration: none; /* Ensure the link text has no underline */
  }
  
  .cta-button:hover {
    background-color: #21a1f1;
    transform: scale(1.1);
  }
  
  .skills {
    margin-top: 40px;
    text-align: center;
    z-index: 1;
  }
  
  .skills h2 {
    font-size: 36px;
    margin-bottom: 20px;
  }
  
  .skills-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    gap: 20px;
    justify-items: center;
  }
  
  .skill-item {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .skill-item p {
    margin-top: 10px;
    font-size: 18px;
  }
  
  .skill-item svg {
    font-size: 48px;
    transition: transform 0.3s ease-in-out;
  }
  
  .skill-item svg:hover {
    transform: scale(1.1);
  }
  
  @keyframes fadeIn {
    0% { opacity: 0; transform: translateY(-20px); }
    100% { opacity: 1; transform: translateY(0); }
  }
  
  @media (max-width: 768px) {
    .home {
      padding: 30px;
    }
  
    .home h1 {
      font-size: 36px;
    }
  
    .home p {
      font-size: 18px;
    }
  
    .cta-button {
      font-size: 16px;
      padding: 8px 16px;
    }
  
    .skills h2 {
      font-size: 30px;
    }
  
    .skills-grid {
      grid-template-columns: repeat(auto-fit, minmax(80px, 1fr));
    }
  
    .skill-item p {
      font-size: 16px;
    }
  
    .skill-item svg {
      font-size: 36px;
    }
  }
  
  @media (max-width: 480px) {
    .home {
      padding: 20px;
    }
  
    .home h1 {
      font-size: 28px;
    }
  
    .home p {
      font-size: 16px;
    }
  
    .cta-button {
      font-size: 14px;
      padding: 6px 12px;
    }
  
    .skills h2 {
      font-size: 24px;
    }
  
    .skills-grid {
      grid-template-columns: repeat(auto-fit, minmax(60px, 1fr));
    }
  
    .skill-item p {
      font-size: 14px;
    }
  
    .skill-item svg {
      font-size: 28px;
    }
  }
  