.project-card {
    background: rgba(255, 255, 255, 0.1);
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    margin: 20px;
    padding: 20px;
    text-align: center;
    cursor: pointer;
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
    max-width: 500px; /* Ensures consistent card width */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  
  .project-card:hover {
    transform: translateY(-10px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
  }
  
  .project-card img {
    width: 100%;
    height: auto;
    border-radius: 10px;
  }
  
  .project-card .special {
    max-width: 150px;
    max-height: 150px;
  }
  
  .project-card .alpha-funder {
    margin-top: 10px;
    max-height: 100px; /* Set a specific max height for the AlphaFunder logo */
  }
  
  .project-card h3 {
    font-size: 24px;
    margin: 10px 0;
  }
  
  .project-card.expanded .project-details {
    max-height: 200px;
    opacity: 1;
    transition: max-height 0.3s ease-in-out, opacity 0.3s ease-in-out;
  }
  
  .project-details {
    max-height: 0;
    opacity: 0;
    overflow: hidden;
    transition: max-height 0.3s ease-in-out, opacity 0.3s ease-in-out;
  }
  
  .project-details p {
    font-size: 18px;
    margin: 10px 0;
  }
  
  .project-details a,
  .coming-soon-button {
    display: inline-block;
    padding: 10px 20px;
    background-color: #61dafb;
    color: #04192e;
    border-radius: 5px;
    text-decoration: none;
    transition: background-color 0.3s ease-in-out;
    cursor: pointer;
  }
  
  .project-details a:hover,
  .project-details .coming-soon-button:hover {
    background-color: #21a1f1;
  }
  
  @media (max-width: 768px) {
    .project-card {
      margin: 15px;
      padding: 15px;
    }
  
    .project-card h3 {
      font-size: 20px;
    }
  
    .project-details p {
      font-size: 16px;
    }
  
    .project-details a,
    .coming-soon-button {
      padding: 8px 16px;
      font-size: 16px;
    }
  }
  
  @media (max-width: 480px) {
    .project-card {
      margin: 10px;
      padding: 10px;
    }
  
    .project-card h3 {
      font-size: 18px;
    }
  
    .project-details p {
      font-size: 14px;
    }
  
    .project-details a,
    .coming-soon-button {
      padding: 6px 12px;
      font-size: 14px;
    }
  }
  