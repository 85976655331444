.projects {
    padding: 50px;
    text-align: center;
    background: url('../../public/background.jpg') no-repeat center center/cover;
    background-size: cover;
    position: relative;
    color: white;
    min-height: calc(100vh - 120px); /* Adjusting for header and footer height */
  }
  
  .projects::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(4, 25, 46, 0.85); /* Dark overlay */
    z-index: 1;
    backdrop-filter: blur(2px); /* Ensure the overlay blurs the background */
  }
  
  .projects h1 {
    font-size: 48px;
    margin-bottom: 20px;
    z-index: 2;
    position: relative;
  }
  
  .project-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    z-index: 2;
    position: relative;
  }
  
  @media (max-width: 768px) {
    .projects {
      padding: 30px;
    }
  
    .projects h1 {
      font-size: 36px;
    }
  }
  
  @media (max-width: 480px) {
    .projects {
      padding: 20px;
    }
  
    .projects h1 {
      font-size: 28px;
    }
  
    .project-card {
      margin: 10px;
      padding: 10px;
    }
  }
  